import React, { useEffect, useState } from 'react';
import {
    GelBoxLayout,
    GelRowLayout,
    GelIcon
} from '@tal-gel/components';
import { getGelTokens } from '@tal-gel/theming';
import { Link } from "react-router-dom";
import {
    NavItemList,
    NavMenuContainer,
    EnabledMenuStyle,
    ActiveMenuStyle,
    StyledListHeader,
    StyledListItem
} from "../../styles/nav.styles";
import { NavProps } from "./nav-props";
import { AdobeAnalytics } from '../../../common/analytics/adobe-analytics';
import { useCookies } from 'react-cookie';
import { AdobePageName } from '../../../constants/constants';

const MiniSideNav = ({ onMenuHideClick, textData, activeMenuItem }: NavProps) => {
    const { global: { themeColorWhite, sizeBaseUnit, themeColorGrayT30, themeColorBackgroundSuccess } } = getGelTokens();


    const [selectedMenuItem, setSelectedMenuItem] = useState(activeMenuItem);
    const [{ tpid }] = useCookies(["tpid"]);

    const onMenuItemClick = (itemId) => {
        const item = textData.menuItems.find((item: { id: number; }) => item.id.toString() === itemId);
        AdobeAnalytics.PushNavClickEvent(tpid, AdobePageName.MenuItem, item.text);
        setSelectedMenuItem(item);
    };

    useEffect(() => {
        setSelectedMenuItem(activeMenuItem)
    })

    return (
        <GelRowLayout >
            <GelBoxLayout>
                <StyledListHeader>
                    <GelIcon
                        name="MinimalRight"
                        width={sizeBaseUnit * 6}
                        style={{ float: "right" }}
                        color={themeColorWhite}
                        onClick={() => onMenuHideClick()}
                    />
                </StyledListHeader>
            </GelBoxLayout>

            <NavMenuContainer>
                {textData.menuItems.map((menuItem) => (
                    <NavItemList key={menuItem.id}>
                        <Link to={menuItem.link} style={{ textDecoration: 'none' }} onClick={() => onMenuItemClick(`${menuItem.id}`)}>
                            <StyledListItem
                                style={(menuItem.id === selectedMenuItem?.id) ? ActiveMenuStyle : EnabledMenuStyle} >
                                <GelBoxLayout>
                                    <GelIcon
                                        name={menuItem.icon}
                                        color={(menuItem.id === selectedMenuItem?.id) ? themeColorBackgroundSuccess :
                                            themeColorGrayT30}
                                        width={sizeBaseUnit * 6}
                                        style={{ paddingLeft: sizeBaseUnit * 3 }}
                                    />
                                </GelBoxLayout>
                            </StyledListItem>
                        </Link>
                    </NavItemList>
                ))}
            </NavMenuContainer>
        </GelRowLayout>
    );
};

export default MiniSideNav;
