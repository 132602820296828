import { useState, useEffect, useContext } from 'react';
import { useCookies } from 'react-cookie';
import { CookieConfig } from '../../constants/constants';
import { UserContext } from '../usercontext/user.context';
import { useOktaAuth } from '@okta/okta-react';

function AuthenticationService() {
    const [{ aid, rid, ssoid, tpid }, setSsoCookies] = useCookies(['aid', 'rid', 'ssoid', 'tpid', 'sid']);
    const [isStandAloneAuthenticated, setIsStandAloneAuthenticated] = useState(false);
    const [isIntegratedAuthenticated, setIsIntegratedAuthenticated] = useState(false);
    const { setUserContext } = useContext(UserContext);
    const { oktaAuth } = useOktaAuth();

    useEffect(() => {
        if (aid && rid && !ssoid)
            setIsStandAloneAuthenticated(true);
        else
            setIsStandAloneAuthenticated(false);

        if (aid && rid && ssoid && tpid)
            setIsIntegratedAuthenticated(true);
        else
            setIsIntegratedAuthenticated(false);

    }, [aid, rid, ssoid, tpid]);

    const clearSession = () => {
        window.sessionStorage.clear();

        setSsoCookies('aid', "", {
            domain: CookieConfig.DOMAIN,
            path: CookieConfig.PATH, secure: true, sameSite: 'lax',
            expires: new Date(new Date().getTime())
        });
        setSsoCookies('rid', "", {
            domain: CookieConfig.DOMAIN,
            path: CookieConfig.PATH, secure: true, sameSite: 'lax',
            expires: new Date(new Date().getTime())
        });

        setUserContext({ claims: [], yourDetails: undefined });

        if (isStandAloneAuthenticated)
            oktaAuth.signOut({ postLogoutRedirectUri: window.location.origin });

    }

    return { isStandAloneAuthenticated, isIntegratedAuthenticated, clearSession };
}

export default AuthenticationService;