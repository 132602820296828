import React from 'react';
import {
    GelRowLayout
} from '@tal-gel/components';
import { getGelTokens } from '@tal-gel/theming';
import RequirementDocuments from '../documentsupload/requirementDocuments';
import NextFuturePayment from '../nextFuturePayment/nextFuturePayment';
import ProcessedPayment from '../processedPayment/processedPayment';
import { NotificationType } from '../../../constants/constants';
import StatusUpdates from '../statusUpdate/statusUdpates';

interface NotificationItemProps {
    requirement: any;
    contentData: any;
    memberNumber: string;
    superfundId: number;
}

const NotificationItem = (props: NotificationItemProps) => {
    const { global: { sizeBaseUnit } } = getGelTokens()
    const { requirement: { type, id, completedDate }, requirement, contentData, memberNumber, superfundId } = props;

    return (
        <GelRowLayout style={{
            paddingBottom: sizeBaseUnit * 4,
            paddingLeft: sizeBaseUnit * 4,
            paddingRight: sizeBaseUnit * 4,
        }}>
            {(type === NotificationType.ProceesedPayment) &&
                <ProcessedPayment key={id} payment={requirement} contentData={contentData}
                    notificationId={id} />}

            {(type === NotificationType.Activity) &&
                <StatusUpdates key={id} activity={requirement} contentData={contentData}
                    notificationId={id} />}

            {type === NotificationType.FuturePayment &&
                <NextFuturePayment futurePayment={requirement} contentData={contentData} />
            }

            {(type !== NotificationType.ProceesedPayment
                && type !== NotificationType.Activity
                && type !== NotificationType.FuturePayment
                && !completedDate) &&
                <RequirementDocuments key={id}
                    contentFullData={contentData?.mmcClaimDetailsCollection?.items[0]}
                    requirement={requirement}
                    memberNumber={memberNumber} superfundId={superfundId} />
            }
        </GelRowLayout>
    );
};

export default NotificationItem;