import React, { useContext } from "react";
import {
    GelRowLayout,
    GelParagraph,
    GelHeading3,
    GelHeading5,
    GelContainerLite,
    GelLink,
    GelColumnLayout,
    GelScreenDetectorContext
} from '@tal-gel/components';
import { getGelTokens } from '@tal-gel/theming';
import styled from "@emotion/styled";
import { AdobeAnalytics } from "../../common/analytics/adobe-analytics";
import { AdobeClickEvents, AdobePageName } from "../../constants/constants";
import { useCookies } from "react-cookie";


const SupportInRecovery = (props) => {
    const { global: { sizeBaseX10, sizeNone, sizeBaseX9, themeColorBackgroundDefault, sizeBaseX4, sizeBaseX2 } } = getGelTokens();

    const { screen, isLargerThanLgScreen } = useContext(GelScreenDetectorContext) as GelScreenDetectorContext;
    const [{ tpid }] = useCookies(["tpid"]);

    const ImageContainer = styled.div`
        position: relative;
        text-align: center;
        background-size: cover;
        background-repeat: no-repeat;
        height: 168px;
        width: 100%;
        background-position: center;
        border-radius: ${sizeBaseX2};
    `

    const onLinkClick = (linkText) => {
        AdobeAnalytics.PushCardClickEvent(tpid, AdobeClickEvents.CardClick, AdobePageName.HealthSupport,
            props.contentData?.mmcHealthAndRecoverySupportCollection?.items[0].supportInRecoveryHeader,
            linkText);
    }

    return (
        <GelContainerLite style={{ padding: `${sizeBaseX10}px ${sizeNone}px` }}>
            <GelRowLayout style={{ paddingBottom: sizeBaseX9 }}>
                <GelHeading3>{props.contentData?.mmcHealthAndRecoverySupportCollection?.items[0].supportInRecoveryHeader}</GelHeading3>
            </GelRowLayout>

            <GelColumnLayout distribution={!isLargerThanLgScreen(screen) ? "1" : "1/2 1/2"}>

                <GelContainerLite style={{ padding: "initial" }}>
                    <GelLink href={props.contentData?.mmcHealthAndRecoverySupportCollection?.items[0].supportServicesLink} target="_blank"
                        onClick={() => onLinkClick(props.contentData?.mmcHealthAndRecoverySupportCollection?.items[0].supportServicesLinkText)}>
                        <ImageContainer id="supportService" style={{ backgroundImage: `url(${props.contentData?.mmcHealthAndRecoverySupportCollection?.items[0].supportServicesImage.url})` }}>

                            <GelContainerLite style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>
                                <GelHeading5 style={{ color: themeColorBackgroundDefault }}>{props.contentData?.mmcHealthAndRecoverySupportCollection?.items[0].supportServicesImage.title}</GelHeading5>
                                <GelParagraph style={{ color: themeColorBackgroundDefault }}>{props.contentData?.mmcHealthAndRecoverySupportCollection?.items[0].supportServicesImage.description}</GelParagraph>
                            </GelContainerLite>
                        </ImageContainer>

                    </GelLink>
                    <GelParagraph style={{ padding: `${sizeBaseX4}px ${sizeNone}px` }}> {props.contentData?.mmcHealthAndRecoverySupportCollection?.items[0].supportServicesSubtext}</GelParagraph>

                    <GelLink href={props.contentData?.mmcHealthAndRecoverySupportCollection?.items[0].supportServicesLink} target="_blank"
                        onClick={() => onLinkClick(props.contentData?.mmcHealthAndRecoverySupportCollection?.items[0].supportServicesLinkText)}>
                        {props.contentData?.mmcHealthAndRecoverySupportCollection?.items[0].supportServicesLinkText}
                    </GelLink>
                </GelContainerLite>

                <GelContainerLite style={{ padding: "initial" }}>
                    <GelLink href={props.contentData?.mmcHealthAndRecoverySupportCollection?.items[0].supportGuidesLink} target="_blank"
                        onClick={() => onLinkClick(props.contentData?.mmcHealthAndRecoverySupportCollection?.items[0].supportGuidesLinkText)}>
                        <ImageContainer id="supportGuide" style={{ backgroundImage: `url(${props.contentData?.mmcHealthAndRecoverySupportCollection?.items[0].supportGuidesImage.url})` }}>
                            <GelContainerLite style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>
                                <GelHeading5 style={{ color: themeColorBackgroundDefault }}>{props.contentData?.mmcHealthAndRecoverySupportCollection?.items[0].supportGuidesImage.title}</GelHeading5>
                                <GelParagraph style={{ color: themeColorBackgroundDefault }}>{props.contentData?.mmcHealthAndRecoverySupportCollection?.items[0].supportGuidesImage.description}</GelParagraph>
                            </GelContainerLite>
                        </ImageContainer>
                    </GelLink>

                    <GelParagraph style={{ padding: `${sizeBaseX4}px ${sizeNone}px` }}> {props.contentData?.mmcHealthAndRecoverySupportCollection?.items[0].supportGuidesSubtext}</GelParagraph>

                    <GelLink href={props.contentData?.mmcHealthAndRecoverySupportCollection?.items[0].supportGuidesLink} target="_blank"
                        onClick={() => onLinkClick(props.contentData?.mmcHealthAndRecoverySupportCollection?.items[0].supportGuidesLinkText)}>
                        {props.contentData?.mmcHealthAndRecoverySupportCollection?.items[0].supportGuidesLinkText}
                    </GelLink>
                </GelContainerLite>

            </GelColumnLayout>
        </GelContainerLite>
    )
};

export default SupportInRecovery;