import { ReactNode, createContext, useState } from "react";
import React from 'react';
import { THEME_NAME } from "../../constants/constants";

type Props = {
    children?: ReactNode;
}

export type ThemeContext = {
    theme: string;
    fund: string;
}

type IThemeContext = {
    themeData: ThemeContext

    setThemeContext: (themeData: ThemeContext) => void
}

const initialValue = {
    themeData: {
        theme: THEME_NAME.TAL,
        fund: THEME_NAME.TAL,
    },
    setThemeContext: () => { }
}

const ThemeContext = createContext<IThemeContext>(initialValue);

const ThemeContextProvider = ({ children }: Props) => {
    const [themeData, setThemeContext] = useState(initialValue.themeData)

    return (
        <ThemeContext.Provider value={{ themeData, setThemeContext }}>
            {children}
        </ThemeContext.Provider>
    );
}

export { ThemeContext, ThemeContextProvider }