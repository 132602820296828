import React, { useEffect, useState } from "react";
import { ReactNode } from "react";
import {
  GelContainerLite,
  GelRowLayout,
  GelLabel,
  GelForm,
  GelFormField,
  GelButton,
  GelIcon,
  GelBoxLayout,
  GelModal
} from '@tal-gel/components';
import { getGelTokens } from '@tal-gel/theming';
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { AdobeClickEvents, AdobePageName, API } from "../../constants/constants";
import { GET_STATUS_DESCRIPTION_PAGE_CONTENT_QUERY } from "../../graphql/queries/graphql-contentful-queries";
import { useCookies } from "react-cookie";
import ClaimStatus from "./claimStatus";
import { AdobeAnalytics } from "../analytics/adobe-analytics";

interface Props {
  title: string;
  subTitle?: string;
  icon?: ReactNode;
  buttonText?: string;
  children: ReactNode;
  showFooter: boolean;
  lightHeader?: boolean;
  claimAssesmentStatus?: ReactNode;
}

const Card = ({ title, subTitle, icon, buttonText, children, showFooter, lightHeader }: Props) => {
  const { global: { sizeNone, sizeBase, sizeBaseUnit, sizeBaseX5, themeColorBackgroundDefault, fontFamilySans,
    sizeBaseX4, themeColorGrayT20, sizeBaseX2, themeColorGrayT05, themeColorBackgroundInverse, themeColorGrayT90 } } = getGelTokens();


  const [contentfulData, setcontenfulData] = useState<null | { mmcStatusDescriptionPageCollection: any }>(null);
  const navigate = useNavigate();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setClaimCookie] = useCookies(['selectedClaim']);
  const [{ tpid }] = useCookies(['tpid']);
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    if (!contentfulData) {
      axios.post(API.CONTENTFUL_URL, {
        query: GET_STATUS_DESCRIPTION_PAGE_CONTENT_QUERY,
        variables: {
          fundName: tpid
        }
      })
        .then(async (response) => {
          setcontenfulData(response.data?.data);
        });
    }
  }, []);

  const onSubmit = () => {
    setClaimCookie('selectedClaim', title);
    AdobeAnalytics.PushUserClickEvent(tpid, AdobeClickEvents.ButtonClick, AdobePageName.ClaimsDashboard, title, buttonText!);
    navigate("/claimdetails", { state: { claimTypeName: title } });
  }

  const Header = styled.div(() => ({
    minHeight: sizeBaseUnit * 15,
    color: themeColorBackgroundDefault,
    fontSize: 10,
    fontFamily: fontFamilySans,
    padding: sizeBaseX4,
    borderRadius: `${sizeBaseUnit * 2}px ${sizeBaseUnit * 2}px 0px 0px`,
    margin: "0px"
  }));

  const Body = styled.div(() => ({
    color: themeColorBackgroundDefault,
    border: "0px solid",
    borderColor: themeColorGrayT20,
    borderRadius: `0px 0px ${sizeBaseUnit * 2}px ${sizeBaseUnit * 2}px`
  }));

  const Footer = styled.div(() => ({
    height: sizeBaseX2 * 10,
    color: themeColorBackgroundDefault,
    paddingTop: sizeBaseX2
  }));

  const onClose = () => {
    setOpenModal(false);
  };

  const openModalWindow = () => {
    setOpenModal(true);
    AdobeAnalytics.PushToolTipClickEvent(tpid, AdobeClickEvents.TooltipClick, subTitle!);
  }

  return (
    <GelContainerLite gutter="medium" style={{
      backgroundColor: themeColorBackgroundDefault,
      border: "1px solid",
      borderColor: themeColorGrayT20,
      padding: "0px",
      borderRadius: sizeBaseUnit * 2,
    }}
    >
      <Header style={{
        backgroundColor: lightHeader ? themeColorGrayT05 :
          themeColorBackgroundInverse
      }}>
        <GelRowLayout gutter="small">

          <h1 style={{
            margin: "0px",
            color: lightHeader ? themeColorGrayT90 : themeColorBackgroundDefault
          }}>{title}</h1>

          <GelBoxLayout distribution="start" wrap={false} space="auto" gutter={sizeNone}>
            {icon &&
              <GelIcon style={{ paddingRight: sizeBaseX2 }}
                name={icon}
                width={sizeBaseX5}
                height={sizeBaseX5}
                color={lightHeader ? themeColorGrayT90 : themeColorBackgroundDefault}
                onClick={() => openModalWindow()} />
            }
            {subTitle &&
              <GelLabel style={{
                fontWeight: 10,
                display: "inline-block",
                color: lightHeader ? themeColorGrayT90 : themeColorBackgroundDefault
              }}>{subTitle}</GelLabel>
            }
          </GelBoxLayout>

          {openModal &&
            <GelModal
              width="sm"
              height={sizeBaseUnit * 170}
              open={openModal}
              onClose={() => setOpenModal(false)}
              sticky
              title={contentfulData?.mmcStatusDescriptionPageCollection.items[0]?.pageTitle}
              action={
                <GelBoxLayout gap="small" space="auto">
                  <GelButton
                    secondary medium
                    width={sizeBaseUnit * 22}
                    onClick={onClose}
                  >
                    {contentfulData?.mmcStatusDescriptionPageCollection.items[0]?.buttonText}
                  </GelButton>
                </GelBoxLayout>
              }
            >
              {
                contentfulData?.mmcStatusDescriptionPageCollection.items[0]?.claimStatuses.map(claimStatus => (
                  <ClaimStatus statusType={claimStatus.StatusType} statusHeader={claimStatus.StatusHeader} statusDescription={claimStatus.StatusDescription} key={crypto.randomUUID()} />
                ))
              }
            </GelModal>
          }
        </GelRowLayout>
      </Header>
      <Body>{children}</Body>

      {showFooter &&
        <Footer style={{ padding: `${sizeBaseX5}px ${sizeBase * 2.5}px 0px ${sizeBase * 2.5}px`, boxSizing: 'content-box' }}>
          <GelForm
            labelAtTop={true}
            onSubmit={onSubmit}>
            <GelFormField width="auto">
              <GelButton style={{
                width: "100%",
              }}
                name=""
                primary large submit>
                {buttonText}
              </GelButton>
            </GelFormField>
          </GelForm>
        </Footer>}
    </GelContainerLite>
  );
}


export default Card;