import React from 'react';
import {
    GelBoxLayout,
    GelParagraph,
    GelContainerLite,
    GelIcon,
    GelLabel,
    GelLink,
    GelTag
} from '@tal-gel/components';
import { getGelTokens } from '@tal-gel/theming';
import { useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { AdobeAnalytics } from '../../common/analytics/adobe-analytics';
import { AdobeClickEvents, AdobePageName } from '../../constants/constants';

const Requiremnet = (Props: { claimType, requirementList, requirementReviewStatus, requirementInCompleteStatus, thirdPartyRequirement, thirdPartyRequirementStatus, requirementStatus }) => {
    const { global: { sizeNone, sizeBaseX3, sizeBaseUnit, themeColorGrayT10, themeColorBackgroundLight, themeColorTextDark,
        sizeBaseX4, themeColorGrayT20, sizeBaseX2, themeColorBorderWarning, themeColorBorderDanger } } = getGelTokens();

    const navigate = useNavigate();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_, setClaimCookie] = useCookies(['selectedClaim']);
    const [{ tpid }] = useCookies(["tpid"]);

    let isPendingRequirement: Boolean = false;


    const navigateToDetails = (reqId, reqName) => {
        setClaimCookie('selectedClaim', Props.claimType);
        AdobeAnalytics.PushUserClickEvent(tpid, AdobeClickEvents.LinkClick, AdobePageName.ClaimsDashboard, Props.claimType, reqName);

        navigate("/claimdetails", { state: { selectedNotification: reqId } });
    };

    const isPendingRequirements = Props.requirementList?.map((req, index) => {   
    if(!req.completedDate){
        isPendingRequirement = true;
        } 
    });

    const requirements = isPendingRequirement ?
        Props.requirementList.filter(req => !req.completedDate).map((req, index) =>
            <GelBoxLayout key={req.id} alignmentIndividual={['center', null]} space={[7, 0.1, 0.1]}
                style={{
                    backgroundColor: themeColorBackgroundLight,
                    paddingTop: sizeBaseX3,
                    paddingBottom: sizeBaseX3,
                    paddingLeft: sizeBaseX4,
                    border: `0px solid ${themeColorGrayT10}`,
                    borderWidth: `${index === 0 ? 1 : 0}px 0px 1px`
                }}
                childStyle={{
                    paddingLeft: '0px',
                    paddingRight: '0px'
                }}>
                <GelLink onClick={() => navigateToDetails(req.id, req.friendlyText)}>{req.friendlyText}</GelLink>
                {(req.receivedDate || req.associatedDocuments?.length) &&
                    <GelTag small warning >
                        <GelBoxLayout
                            distribution='center'
                            alignment='center'
                            space={[1, 20]}
                            style={{
                                gap: `${sizeBaseUnit}px`,
                                paddingLeft: `${sizeBaseX2}px`,
                                paddingRight: `${sizeBaseX2}px`
                            }}
                            childStyle={{
                                paddingLeft: `${sizeNone}px`,
                                paddingRight: `${sizeNone}px`
                            }}>
                            <GelIcon
                                name="Circle"
                                width={sizeBaseUnit * 2}
                                color={themeColorBorderWarning}></GelIcon>
                            <GelParagraph style={{ fontSize: sizeBaseX3, color: themeColorTextDark }}> {Props.requirementReviewStatus}</GelParagraph>
                        </GelBoxLayout>
                    </GelTag>
                }
                {!req.receivedDate && !req.associatedDocuments?.length &&
                    <GelTag small danger >
                        <GelBoxLayout
                            distribution='center'
                            alignment='center'
                            space={[1, 20]}
                            style={{
                                gap: `${sizeBaseUnit}px`,
                                paddingLeft: `${sizeBaseX2}px`,
                                paddingRight: `${sizeBaseX2}px`
                            }}
                            childStyle={{
                                paddingLeft: `${sizeNone}px`,
                                paddingRight: `${sizeNone}px`
                            }}>
                            <GelIcon
                                name="Circle"
                                width={sizeBaseUnit * 2}
                                color={themeColorBorderDanger}></GelIcon>
                            {Props.requirementInCompleteStatus}
                        </GelBoxLayout>
                    </GelTag>
                }
            </GelBoxLayout>
        )
        :
        <GelBoxLayout alignmentIndividual={['center', null]} space={[7, 0.1, 0.1]} style={{
            backgroundColor: themeColorBackgroundLight,
            paddingTop: sizeBaseX3,
            paddingBottom: sizeBaseX3,
            paddingLeft: sizeBaseX3,
            border: `0px solid ${themeColorGrayT20}`,
            borderWidth: `1px 0px 1px`
        }}>
            {!isPendingRequirement && Props.thirdPartyRequirement &&
                <GelLabel>{Props.thirdPartyRequirementStatus}</GelLabel>
            }
            {!isPendingRequirement && !Props.thirdPartyRequirement &&
                <GelLabel>{Props.requirementStatus}</GelLabel>
            }
        </GelBoxLayout>

    return (
        <GelContainerLite gutter="medium" style={{
            paddingBottom: sizeNone,
            paddingTop: sizeNone,
            paddingLeft: sizeBaseX2,
            paddingRight: sizeBaseX2,
        }}>
            {requirements}
        </GelContainerLite>
    );
};

export default Requiremnet;