import React, { useEffect, useState } from "react";
import {
  GelRowLayout,
  GelLabel
} from '@tal-gel/components';
import { getGelTokens } from '@tal-gel/theming';
import axios from "axios";
import { GET_FUND_BANNER_DETAILS_CONTENT_QUERY } from "../../graphql/queries/graphql-contentful-queries";
import { useCookies } from "react-cookie";
import { API, THEME_CODE } from "../../constants/constants";

const FundBanner = () => {
  const [{ tpid }] = useCookies(['tpid']);
  const [contentfulData, setcontenfulData] = useState<null | { mmcFundBannerCollection: any }>(null);

  useEffect(() => {
    axios.post(API.CONTENTFUL_URL, {
      query: GET_FUND_BANNER_DETAILS_CONTENT_QUERY,
      variables: {
        fundName: tpid ? tpid : THEME_CODE.TAL
      }
    })
      .then(async (response) => {
        setcontenfulData(response.data?.data);
      });
  }, []);

  return (
    <div style={{
      backgroundColor: getGelTokens().global.themeColorBackgroundInverse,
      color: getGelTokens().global.themeColorBackgroundDefault,
      fontSize: getGelTokens().global.fontSizeDisplayLarge,
      fontFamily: getGelTokens().global.fontFamilySans,
      marginRight: -getGelTokens().global.sizeBaseUnit * 4,
      position: "relative",
      height: "70vh"
    }}>
      <GelRowLayout gutter="xsmall">
        <GelLabel style={{
          fontSize: getGelTokens().global.fontSizeDisplayMedium,
          fontWeight: getGelTokens().global.fontWeightBold,
          display: "inline-block",
          marginLeft: getGelTokens().global.sizeBaseUnit * 10,
          color: getGelTokens().global.themeColorTextInverse,
          paddingTop: getGelTokens().global.sizeBaseUnit * 10,
          paddingRight: getGelTokens().global.sizeBaseUnit * 10
        }}>
          {contentfulData?.mmcFundBannerCollection?.items[0].headerText}
        </GelLabel>
        <GelLabel style={{
          fontWeight: 400,
          fontSize: getGelTokens().global.fontSizeBodyXLarge,
          marginLeft: getGelTokens().global.sizeBaseUnit * 10,
          color: getGelTokens().global.themeColorTextInverse,
          position: "absolute",
          paddingRight: getGelTokens().global.sizeBaseUnit * 20
        }}>
          {contentfulData?.mmcFundBannerCollection?.items[0].subHeaderText}
        </GelLabel>
      </GelRowLayout>
    </div>
  );
};

export default FundBanner;