import React, { useContext, useEffect, useState } from 'react';
import { AdobeClickEvents, AdobePageName, API } from '../../constants/constants';
import { GET_MESSAGEHUB_DETAILS_CONTENT_QUERY } from '../../graphql/queries/graphql-contentful-queries';
import {
    GelScreenDetectorContext,
    GelRowLayout,
    GelContainerLite,
    GelBoxLayout,
    GelParagraph,
    GelHeading5,
    GelHeading2,
    GelSpinner,
    GelIcon
} from '@tal-gel/components';
import { getGelTokens } from '@tal-gel/theming';
import styled from '@emotion/styled';
import axios from 'axios';
import { UserContext } from '../../common/usercontext/user.context';
import Card from '../../common/components/card';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import ResponsiveContainer from '../common/responsive.container';
import { CorroItem } from './CorroItem';
import Flexbox from '../../common/components/FlexBox';
import Pagination from '../../common/components/Pagination';
import SESSION_STORAGE_KEY from '../../constants/storage.constant';
import { GET_LIST_OF_DIGICORRESPONDENCE_QUERY, GET_MEMBER_DIGICORRESPONDENCE_DOCUMENT_QUERY } from '../../graphql/queries/graphql-queries.constant';
import { DigiCorrespondence } from '../../common/models/memberclaims';
import { SaveFile } from '../../common/file.utils';
import { AdobeAnalytics } from '../../common/analytics/adobe-analytics';


const MessageHubContainer = () => {
    const { global: { sizeBaseX15, sizeBaseX10, themeColorBackgroundLight, sizeBaseX3, sizeNone, sizeBaseUnit, sizeBaseX11,
        themeColorTextDark, sizeBaseX5, themeColorInteractive1, sizeBaseX8, sizeBaseX4, themeColorStateDisabled, sizeBaseX2, themeColorGrayT50 } } = getGelTokens();


    const navigate = useNavigate();
    const { contextData, setUserContext } = useContext(UserContext)
    const [{ aid, rid, ssoid, tpid }] = useCookies(['aid', 'rid', 'ssoid', 'tpid']);
    const { screen, isXsScreen } = useContext(GelScreenDetectorContext) as GelScreenDetectorContext;

    const [contentDataLoaded, setcontentDataLoaded] = useState(false);
    const [digiCorrespondenceLoaded, setDigiCorrespondenceLoaded] = useState(false);
    const [contentfulData, setcontenfulData] = useState<null | { mmcMessageHubPageCollection: any }>(null);

    const [messages, setMessages] = useState<DigiCorrespondence[]>([]);
    const [pageNumber, setPageNumber] = useState(1);

    const messagesPerPage = 10;
    const pageCount = Math.ceil(messages.length / messagesPerPage)

    if (contextData.memberDetails?.displayLegalDisclosure)
        navigate("/yourDeclaration");

    useEffect(() => {
        setDigiCorrespondenceLoaded(false);

        axios.post(API.CONTENTFUL_URL, {
            query: GET_MESSAGEHUB_DETAILS_CONTENT_QUERY,
            variables: {
                fundName: tpid
            }
        })
            .then(async (response) => {
                setcontentDataLoaded(true);
                setcontenfulData(response.data?.data);
            });

        axios.post(API.BASEURL, {
            query: GET_LIST_OF_DIGICORRESPONDENCE_QUERY,
        },
            {
                headers: {
                    'x-aid': aid,
                    'x-rid': rid,
                    ssoid,
                    tpid,
                    'sid': window.sessionStorage.getItem(SESSION_STORAGE_KEY.SESSIONID_TOKEN)
                }
            })
            .then(async (response) => {
                let digoCorroMessages = response.data.data.listDigiCorrespondences as DigiCorrespondence[];
                setMessages(digoCorroMessages);
                setDigiCorrespondenceLoaded(true);

                // Get number of unread DigiCorro messages
                let unreadDigiCorroMessages = digoCorroMessages.filter(message => message.isRead === false);

                setUserContext({
                    ...contextData,
                    memberDetails: {
                        ...contextData.memberDetails,
                        unReadDigiCorroMessageCount: unreadDigiCorroMessages.length
                    }
                });
            })
            .catch((error) => {
                console.log(error)
            })
    }, []);

    if (!contentDataLoaded || !digiCorrespondenceLoaded)
        return <GelSpinner medium overlay />

    const Spacer = styled.div(() => ({
        paddingTop: sizeBaseX5
    }));

    const PageHeader = styled.div(() => ({
        padding: isXsScreen(screen) ? `${sizeBaseX15}px ${sizeBaseX5}px` : `${sizeBaseX15}px ${sizeBaseX10}px`,
        backgroundColor: themeColorBackgroundLight
    }));

    const onFilterClick = () => {
        console.log('Filter clicked')
    }

    const onCorroItemClick = (id: number) => {

        AdobeAnalytics.PushUserClickEvent(tpid, AdobeClickEvents.ButtonClick, AdobePageName.MessageHub, contentfulData?.mmcMessageHubPageCollection.items[0].correspondenceHeader, "Download Message")
        console.log(`Downloading DigiCorrespondence document with id: ${id}, `);

        axios.post(API.BASEURL, {
            query: GET_MEMBER_DIGICORRESPONDENCE_DOCUMENT_QUERY,
            variables: {
                digiCorroId: id,
            }
        },
            {
                headers: {
                    'x-aid': aid,
                    'x-rid': rid,
                    ssoid,
                    tpid,
                    'sid': window.sessionStorage.getItem(SESSION_STORAGE_KEY.SESSIONID_TOKEN)
                }
            })
            .then(async (response) => {
                let selectedDigiCorroDoc = messages.find(message => message.digiCorroId === id);
                let content = response.data.data.downloadDigiCorrospondenceDocument.content;
                let fileName = selectedDigiCorroDoc!.documentTitle;
                console.log(`Saving DigiCorrespondence document with id: ${id}, fileName: ${fileName}`);
                SaveFile(content, fileName);

                // Decrement unread DigiCorro message count if message hasnt been read
                if (selectedDigiCorroDoc?.isRead === false) {
                    let currentUnreadDigiCorroMessageCount = contextData?.memberDetails?.unReadDigiCorroMessageCount ?? 0;
                    setUserContext({
                        ...contextData,
                        memberDetails: {
                            ...contextData.memberDetails,
                            unReadDigiCorroMessageCount: currentUnreadDigiCorroMessageCount - 1
                        }
                    });
                    console.log(`Decremented unread DigiCorro message count(${currentUnreadDigiCorroMessageCount} -> ${currentUnreadDigiCorroMessageCount - 1})`);
                }

                // Mark document as read
                setMessages(messages.map(message =>
                    message.digiCorroId === id
                        ? { ...message, isRead: true }
                        : message
                ));
            })
            .catch((error) => {
                console.log(error)
            })
    };

    const getPaginatedMessages = (): DigiCorrespondence[] => {
        const startIndex = (pageNumber - 1) * messagesPerPage;
        const endIndex = startIndex + messagesPerPage;
        return messages.slice(startIndex, endIndex);
    };

    const diplayMessages = () => {
        return messages.length > 0
    }

    const getRemainderItems = () => Array.from({ length: messagesPerPage - getPaginatedMessages().length }, (_, index) => index)

    return (
        <GelContainerLite style={{ padding: sizeNone, overflow: "hidden" }}>
            <PageHeader >
                <GelHeading2>{contentfulData?.mmcMessageHubPageCollection?.items[0]?.pageHeader}</GelHeading2>
                <GelParagraph style={{ paddingTop: sizeBaseX3 }}>{contentfulData?.mmcMessageHubPageCollection?.items[0]?.pageSubtext}</GelParagraph>
            </PageHeader>

            <ResponsiveContainer>
                <GelRowLayout style={{ padding: isXsScreen(screen) ? `${sizeBaseX10}px ${sizeBaseX5}px` : `${sizeBaseX5}px ${sizeBaseX10}px` }}>
                    <Card title={contentfulData?.mmcMessageHubPageCollection?.items[0]?.correspondenceHeader}
                        subTitle={contentfulData?.mmcMessageHubPageCollection?.items[0]?.correspondenceSubText}
                        showFooter={false}>
                        <GelContainerLite style={{ padding: `${sizeBaseX8}px ${sizeBaseX4}px` }}>
                            <div style={{
                                height: sizeBaseX11,
                                backgroundColor: diplayMessages() ? themeColorInteractive1 : themeColorStateDisabled,
                                borderRadius: 4,
                                cursor: diplayMessages() ? "pointer" : "not-allowed",
                                position: "relative",
                                display: "none" // Todo: Implement Filter functionality - Hide until then
                            }}
                                onClick={diplayMessages() ? onFilterClick : undefined}>
                                <GelBoxLayout style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>
                                    <GelParagraph style={{
                                        marginRight: -sizeBaseUnit,
                                        color: diplayMessages() ? themeColorTextDark : themeColorGrayT50,
                                        fontWeight: '700',
                                        fontSize: '16px'
                                    }}>{contentfulData?.mmcMessageHubPageCollection?.items[0]?.filterText ?? 'FILTER'}</GelParagraph>

                                    <GelIcon name="InfoCircle" inline large
                                        width={sizeBaseUnit * 5}
                                        height={sizeBaseUnit * 5}
                                        marginBottom={3}
                                        color={diplayMessages() ? themeColorTextDark : themeColorGrayT50}
                                        style={{ paddingBotom: '4px' }} />
                                </GelBoxLayout>
                            </div>

                            {/* Todo: Implement Filter functionality - Hide until then */}
                            {/* {false && <>
                                <Spacer />
                                <HorizontalSeperator />
                                <Spacer />
                            </>} */}

                            <Flexbox gap={sizeBaseX2} flexDirection="column" style={messages.length === 0 ? { height: '384px' } : {}}>
                                {messages.length > 0 && getPaginatedMessages().map(message =>
                                    <CorroItem
                                        key={message.digiCorroId}
                                        date={message.createdOn}
                                        text={`${message.fineosClaimCaseNumber} ${message.claimType}`}
                                        title={message.classification}
                                        variant={message.isRead ? "default" : "unread"}
                                        onClick={() => onCorroItemClick(message.digiCorroId)}
                                    />)}

                                {messages.length > 0 && getRemainderItems().map(idx => <CorroItem key={`space-digicorro-${idx}`} variant={"hidden"} />)}

                                {messages.length === 0 && <div style={{ position: "absolute", top: "30%", left: "50%", transform: "translate(-50%, -50%)", width: "100%" }}>
                                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                                        <GelHeading5 style={{ paddingTop: "10%", color: "#54544C" }}>{"No Results"}</GelHeading5>
                                        <GelParagraph style={{ paddingTop: "8px", color: "#54544C", fontWeight: "400" }}>{"You have no correspondence available"}</GelParagraph>
                                    </div>
                                </div>}
                            </Flexbox>

                            {messages.length > 0 &&
                                <>
                                    <Spacer />
                                    <Pagination page={pageNumber} count={pageCount} onChange={setPageNumber} />
                                </>
                            }

                        </GelContainerLite>
                    </Card>
                </GelRowLayout>
            </ResponsiveContainer>

        </GelContainerLite>
    );
};

export default MessageHubContainer;