import { GelThemeProvider } from '@tal-gel/theming';
import { GelScreenDetector } from '@tal-gel/components';
import React, { useContext } from 'react';
import { HashRouter } from 'react-router-dom';
import App from './App';
import { ThemeContext } from './common/usercontext/themes.context';
import { THEME_NAME } from './constants/constants';

const GelThemes = () => {

  const { themeData } = useContext(ThemeContext)

  function getAppTheme() { 
    return themeData?.theme || THEME_NAME.TAL;
   } 

    return (
        <GelThemeProvider theme={getAppTheme()}>
          <GelScreenDetector>
            <HashRouter>
              <App />
            </HashRouter>
          </GelScreenDetector>
        </GelThemeProvider>
    );
}

export default GelThemes;