import { ADOBE_ANALYTICS_SOURCE, AdobeClickEvents, AdobePageName, SuperFundBrandingAdobe } from "../../constants/constants";

export const AdobeAnalytics = {

    PushPageLoadEvent(fund: string, pageName: string): void {

        if (ADOBE_ANALYTICS_SOURCE.indexOf('ADOBE_ANALYTICS_URL') > -1) {
            return;
        }

        try {
            (window as any).appEventData.push({
                'event': 'pageview',
                'fund': SuperFundBrandingAdobe[fund],
                'featureName': 'MMC',
                'pageName': pageName,
                'pageUrl': window.location.href
            });
        }
        catch {
            console.log("Unable to publish event to adobe analytics.");
        }
    },

    PushUserClickEvent(fund: string,clickType:string, pageName: string,pageSection:string,clickText:string): void {
        if (ADOBE_ANALYTICS_SOURCE.indexOf('ADOBE_ANALYTICS_URL') > -1) {
            return;
        }

        try {
            (window as any).appEventData.push({
                'event': clickType,
                'fund': SuperFundBrandingAdobe[fund],
                'featureName': 'MMC',
                'pageUrl': window.location.href,
                'pageName': pageName,
                'pageSection':pageSection,
                'clickText':clickText
            });
        }
        catch {
            console.log("Unable to publish event to adobe analytics.");
        }
    },

    PushCardClickEvent(fund: string,clickType:string, pageName: string,cardTitle:string,clickText:string): void {
        if (ADOBE_ANALYTICS_SOURCE.indexOf('ADOBE_ANALYTICS_URL') > -1) {
            return;
        }

        try {
            (window as any).appEventData.push({
                'event': clickType,
                'fund': SuperFundBrandingAdobe[fund],
                'featureName': 'MMC',
                'pageUrl': window.location.href,
                'pageName': pageName,
                'cardTitle':cardTitle,
                'clickText':clickText
            });

        }
        catch {
            console.log("Unable to publish event to adobe analytics.");
        }
    },

    PushAccordionClickEvent(fund: string,clickType:string, pageName: string,cardTitle:string): void {
        if (ADOBE_ANALYTICS_SOURCE.indexOf('ADOBE_ANALYTICS_URL') > -1) {
            return;
        }
        try {
            (window as any).appEventData.push({
                'event': clickType,
                'fund': SuperFundBrandingAdobe[fund],
                'featureName': 'MMC',
                'pageUrl': window.location.href,
                'pageName': pageName,
                'cardTitle':cardTitle
            });
        }
        catch {
            console.log("Unable to publish event to adobe analytics.");
        }
    },

    PushToolTipClickEvent(fund: string,clickType:string, cardTitle:string): void {
        if (ADOBE_ANALYTICS_SOURCE.indexOf('ADOBE_ANALYTICS_URL') > -1) {
            return;
        }

        let pageName: string = '';

        if(window.location.href.indexOf('/#/claims') > -1){
            pageName = AdobePageName.ClaimsDashboard;
        }else if(window.location.href.indexOf('/#/claimdetails') > -1){
            pageName = AdobePageName.ClaimDetails;
        }
        
        try {
            (window as any).appEventData.push({
                'event': clickType,
                'fund': SuperFundBrandingAdobe[fund],
                'featureName': 'MMC',
                'pageUrl': window.location.href,
                'pageName': pageName,
                'cardTitle':cardTitle
            });
        }
        catch {
            console.log("Unable to publish event to adobe analytics.");
        }
    },
    PushNavClickEvent(fund: string, pageSection:string,clickText:string): void {
        if (ADOBE_ANALYTICS_SOURCE.indexOf('ADOBE_ANALYTICS_URL') > -1) {
            return;
        }

        try {
            (window as any).appEventData.push({
                'event': AdobeClickEvents.ButtonClick,
                'fund': SuperFundBrandingAdobe[fund],
                'featureName': 'MMC',
                'pageUrl': window.location.href,
                'pageName': clickText,
                'pageSection':pageSection,
                'clickText':clickText
            });
        }
        catch {
            console.log("Unable to publish event to adobe analytics.");
        }
    }
}
