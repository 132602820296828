/**
 * Represents the different super fund prefix types
 * 
 * @enum {string}
 * @description 
 * Enumeration of super fund prefixes used for identifying and routing different super funds
 * 
 * @property {string} AWR - Aware Super fund prefix
 * @property {string} AMP - AMP Super fund prefix
 * @property {string} SMC - ANZ Smart Choice Super fund prefix
 * @property {string} AUF - AUS Food Super fund prefix
 * @property {string} VIC - Aware Super - ex VicSuper
 * @property {string} FUL - Fulcrum Super fund prefix
 * @property {string} TSF - Mine Super fund prefix
 * @property {string} PFS - MLC Super fund prefix
 * @property {string} TWS - TWU Super fund prefix
 * @property {string} VIS - Victorian Independent Schools prefix
 * @property {string} WAS - WA Super fund prefix
 * 
 * @example
 * const fundPrefix = SuperFundPrefixEnum.AMP;
 * console.log(fundPrefix); // Outputs: "amp"
 */
export enum SuperFundPrefixEnum {
    AWR = "awr",
    AMP = "amp",
    SMC = "smc",
    AUF = "auf",
    VIC = "vic",
    FUL = "ful",
    TSF = "tsf",
    PFS = "pfs",
    TWS = "tws",
    VIS = "vis",
    WAS = "was"
}

/**
 * Mapping of Super Fund Prefix Enums to their corresponding login paths
 * 
 * @type {Record<SuperFundPrefixEnum, string>}
 * @description
 * A record that maps each Super Fund Prefix to its specific login route
 * 
 * @example
 * // Get login path for AMP Super
 * const ampLoginPath = superFundPrefixPathMap[SuperFundPrefixEnum.AMP];
 * console.log(ampLoginPath); // Outputs: "/login"
 * 
 * @example
 * // Using in navigation
 * navigate(superFundPrefixPathMap[currentFundPrefix]);
 */
export const superFundPrefixPathMap: Record<SuperFundPrefixEnum, string> = {
    [SuperFundPrefixEnum.AWR]: "/login/aware",
    [SuperFundPrefixEnum.AMP]: "/login",
    [SuperFundPrefixEnum.SMC]: "/login/anzsmartchoicesuper",
    [SuperFundPrefixEnum.AUF]: "/login/ausfoodsuper",
    [SuperFundPrefixEnum.VIC]: "/login/aware",
    [SuperFundPrefixEnum.FUL]: "/login/fulcrum",
    [SuperFundPrefixEnum.TSF]: "/login/mine",
    [SuperFundPrefixEnum.PFS]: "/login/mlcsuper",
    [SuperFundPrefixEnum.TWS]: "/login/twusuper",
    [SuperFundPrefixEnum.VIS]: "/login/aware",
    [SuperFundPrefixEnum.WAS]: "/login/aware"
};