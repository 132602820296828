import React, { useContext, useEffect, useState } from 'react';
import {
  GelForm, GelFormField, GelTextInput,
  useGelFormData,
  GelButton,
  GelBoxLayout,
  GelScreenDetectorContext,
  GelContainerLite,
  GelRowLayout,
  GelIcon,
  GelLabel,
  GelLink,
  GelHeading3,
  GelParagraph,
  GelSpinner
} from '@tal-gel/components';

import { getGelTokens } from '@tal-gel/theming';
import { useNavigate } from "react-router-dom";
import { AccountApi } from '../../common/api/auth.provider';
import { UserContext } from '../../common/usercontext/user.context';
import { API } from '../../constants/constants';
import { GET_CONFIRM_MOBILE_PAGE_CONTENT_QUERY } from '../../graphql/queries/graphql-contentful-queries';
import styled from '@emotion/styled';
import axios from 'axios';
import FundBanner from '../common/fundBanner';

const ConfirmMobile = () => {
  const { global: { sizeNone, sizeBaseUnit, themeColorTextDanger, sizeBaseX5, themeColorBackgroundLight, themeColorGrayT20, themeColorGrayT40, sizeBaseX2 } } = getGelTokens()

  const navigate = useNavigate();
  const { screen, isXsScreen, isLargerThanSmScreen } = useContext(GelScreenDetectorContext) as GelScreenDetectorContext;
  const [loginStatusMessage, _] = useState("");
  const { setUserContext } = useContext(UserContext);
  const [contentDataLoaded, setcontentDataLoaded] = useState(false);
  const [contentfulData, setcontenfulData] = useState<null | { mmcConfirmMobilePageCollection: any }>(null);
  let userPhoneNumber = "";

  const {
    formData,
    onFormDataReset,
    resetForm
  } = useGelFormData({
    mobileNumber: ''
  });

  const { contextData } = useContext(UserContext)
  console.log('contextData confirm mobile pwd', contextData);

  const ResetMobileDiv = styled.div(() => ({
    paddingBottom: sizeBaseX5
  }));

  useEffect(() => {
    if (contextData.memberDetails?.mobileNumber) {
      userPhoneNumber = contextData.memberDetails?.mobileNumber;
      formData.mobileNumber = contextData.memberDetails?.mobileNumber;
    }
    else {
      navigate("/error");
    }

    axios.post(API.CONTENTFUL_URL, {
      query: GET_CONFIRM_MOBILE_PAGE_CONTENT_QUERY,
    })
      .then(async (response) => {
        setcontentDataLoaded(true);
        setcontenfulData(response.data?.data);
      });
  }, []);

  const onSubmit = () => {
    console.log('contextData onSubmit', contextData, userPhoneNumber);
    setUserContext({ memberDetails: { factorId: '', stateToken: contextData.memberDetails?.stateToken, mobileNumber: userPhoneNumber }, claims: [] });

    AccountApi.sendMfaSms(contextData.memberDetails?.factorId!, contextData.memberDetails?.stateToken!)
      .then(response => response.json())
      .then(data => {
        setUserContext({
          memberDetails: {
            factorId: contextData.memberDetails?.factorId,
            stateToken: data.stateToken,
            mobileNumber: contextData.memberDetails?.mobileNumber,
            oktaEmail: contextData.memberDetails?.oktaEmail
          },
          claims: []
        });
        navigate("/verifycode");
      })
      .catch(error => {
        console.error('failed to send MFA sms', error);
        //return Promise.reject(error);
      });
    navigate("/verifycode");
  };

  const navigateToMobileReset = () => {
    navigate("/confirmmember");
  };

  if (!contentDataLoaded)
    return (<GelSpinner medium overlay />)

  return (
    <GelContainerLite style={{
      paddingRight: sizeNone,
      paddingLeft: sizeNone,
      paddingBottom: sizeBaseUnit * 15,
      paddingTop: sizeBaseUnit * 15, overflow: "hidden"
    }}>
      <GelBoxLayout alignment="start" space={[1, 4]} gutter="none" >
        <GelForm
          labelAtTop={true}
          width={isXsScreen(screen) ?
            '100%' : sizeBaseUnit * 110
          }
          {...!isXsScreen(screen) && {
            labelWidth: sizeBaseUnit * 50
          }}
          fieldGutter={sizeBaseUnit * 10}
          disableOnSubmit
          onSubmit={onSubmit}
          reset={resetForm}
          onReset={onFormDataReset}
          parseResponseBody={false}
          style={{
            paddingRight: sizeBaseUnit * 16,
            paddingLeft: sizeBaseUnit * 16
          }}
        >
          <GelRowLayout gutter="medium" style={{ paddingLeft: sizeBaseUnit * 3 }}>
            <GelHeading3 style={{ padding: "20px 0px" }}>
              {contentfulData?.mmcConfirmMobilePageCollection.items[0]?.headerText}
            </GelHeading3>
            <GelParagraph>
              {contentfulData?.mmcConfirmMobilePageCollection.items[0]?.subText}
            </GelParagraph>

            <GelFormField
              label={contentfulData?.mmcConfirmMobilePageCollection?.items[0]?.confirmMobileText}
            >
              <GelTextInput
                name="mobileNumber"
                value={formData.mobileNumber}
                readOnly='true'
                style={{
                  backgroundColor: themeColorBackgroundLight,
                  borderColor: themeColorGrayT20,
                  color: themeColorGrayT40
                }}
              />
            </GelFormField>


            <GelFormField width="auto" style={{ paddingTop: sizeBaseUnit * 4, paddingBottom: sizeBaseUnit * 4 }}>
              <GelButton
                name="" style={{
                  width: "100%"
                }}
                primary large submit
              >
                {contentfulData?.mmcConfirmMobilePageCollection.items[0]?.confirmButtonText}
              </GelButton>
            </GelFormField>

            <ResetMobileDiv>
              <GelLabel style={{
                fontWeight: 100,
                paddingRight: sizeBaseX2
              }}>{contentfulData?.mmcConfirmMobilePageCollection?.items[0]?.resetMobileText}
              </GelLabel>
              <GelLink onClick={navigateToMobileReset}> {contentfulData?.mmcConfirmMobilePageCollection?.items[0]?.resetMobileLink}</GelLink>
            </ResetMobileDiv>

            <GelBoxLayout space="auto">
              {loginStatusMessage &&
                <GelLabel style={{ color: themeColorTextDanger }}><GelIcon color={themeColorTextDanger} name="AlertCircle" inline />{loginStatusMessage}</GelLabel>}
            </GelBoxLayout>
          </GelRowLayout>
        </GelForm>
        {isLargerThanSmScreen(screen) &&
          <FundBanner></FundBanner>}
      </GelBoxLayout>
    </GelContainerLite>
  );
};

export default ConfirmMobile;