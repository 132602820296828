import React, { useEffect, useState } from "react";
import { useContext } from "react";
import {
    GelPageLayoutContext,
    GelScreenDetectorContext,
    GelSpinner
} from '@tal-gel/components';
import SideNav from "./side-nav";
import MiniSideNav from "./side-nav-mini";
import TopNavMob from "./top-nav-mob";
import MiniTopNavMob from "./top-nav-mob-mini";
import { GET_TRAYMENU_ITEMS_CONTENT_QUERY } from "../../../graphql/queries/graphql-contentful-queries";
import { API } from "../../../constants/constants";
import { useLocation } from "react-router";
import axios from "axios";
import { useCookies } from "react-cookie";
import { UserContext } from '../../../common/usercontext/user.context';
interface ContextNavOpen {
    isSideNavOpen: boolean;
    openSideNav: (navopen: boolean) => void
}

const PageNavigation = () => {

    const { isSideNavOpen, openSideNav } = useContext<ContextNavOpen>(GelPageLayoutContext);
    const { screen, isXsScreen } = useContext(GelScreenDetectorContext) as GelScreenDetectorContext;
    const [contentDataLoaded, setcontentDataLoaded] = useState(false);
    const [contentfulData, setcontenfulData] = useState<null | { mmcTrayMenuItemsCollection: any }>(null);
    const [{ tpid }] = useCookies(['tpid']);
    const { contextData } = useContext(UserContext);
    const location = useLocation();
    var isMemberClaimAvailble = true;

    if (contextData.claims?.length === 0 && contextData.memberDetails?.memberNumber) {
        isMemberClaimAvailble = false;
    }

    const toggleMenu = () => {
        openSideNav(!isSideNavOpen);
    };

    useEffect(() => {
        axios.post(API.CONTENTFUL_URL, {
            query: GET_TRAYMENU_ITEMS_CONTENT_QUERY,
            variables: {
                fundName: tpid
            }
        })
            .then(async (response) => {
                setcontentDataLoaded(true);
                setcontenfulData(response.data?.data);
            });
    }, []);

    let selectedMenuItem = contentfulData?.mmcTrayMenuItemsCollection?.items[0].menuItems.filter((item) =>
        item?.link === location?.pathname.split('/')[1])[0];

    
    if (!contentDataLoaded) {
        return <GelSpinner medium overlay />
    }

    if (contextData.memberDetails?.memberNumber &&
        isMemberClaimAvailble &&
        contentfulData?.mmcTrayMenuItemsCollection &&
        contentfulData?.mmcTrayMenuItemsCollection?.items.length > 0
    ) {
        if (isXsScreen(screen)) {
            const MobileNavBar = isSideNavOpen ? TopNavMob : MiniTopNavMob;
            return (
                <MobileNavBar onMenuHideClick={toggleMenu} textData={contentfulData?.mmcTrayMenuItemsCollection?.items[0]} activeMenuItem={selectedMenuItem} />
            )
        } else {
            const DesktopNavBar = isSideNavOpen ? SideNav : MiniSideNav;

            return (
                <DesktopNavBar onMenuHideClick={toggleMenu} textData={contentfulData?.mmcTrayMenuItemsCollection?.items[0]} activeMenuItem={selectedMenuItem} />
            )
        }
    }
};

export default PageNavigation;