import React, { useContext } from 'react';
import styled from "@emotion/styled"
import {
    GelIcon,
    GelBoxLayout,
    GelParagraph,
    GelScreenDetectorContext
} from '@tal-gel/components';
import { getGelTokens } from '@tal-gel/theming';

export enum TagStatus {
    SUCCESS = "success",
    WARNING = "warning",
    DANGER = "danger",
}

const Tag = ({ text, status }) => {
    const { screen, isXsScreen } = useContext(GelScreenDetectorContext) as GelScreenDetectorContext;
    const { global: { sizeBaseX2, sizeBaseX3, themeColorTextDark, sizeBaseUnit, sizeNone } } = getGelTokens();


    return (
        <TagWrapper isMobile={!isXsScreen(screen)} status={status} id="tag">
            <GelBoxLayout
                distribution="center"
                alignment="center"
                space={[0.5, 10]}
                style={{
                    gap: `${sizeBaseUnit}px`,
                    paddingLeft: `${sizeBaseX2}px`,
                    paddingRight: `${sizeBaseX2}px`,
                }}
                childStyle={{
                    paddingLeft: `${sizeNone}px`,
                    paddingRight: `${sizeNone}px`
                }}
            >
                <GelIcon
                    name="Circle"
                    width={sizeBaseUnit * 2}
                    color={getStatusIconColor(status)}
                />
                <GelParagraph
                    style={{
                        fontSize: sizeBaseX3,
                        paddingRight: sizeBaseUnit,
                        color: themeColorTextDark
                    }}
                >
                    {text}
                </GelParagraph>
            </GelBoxLayout>
        </TagWrapper>
    );
};



const getStatusIconColor = (status: TagStatus | undefined) => {
    const { global: { themeColorBackgroundSuccess, themeColorBackgroundDanger, themeColorBackgroundWarning } } = getGelTokens();

    switch (status) {
        case TagStatus.SUCCESS:
            return themeColorBackgroundSuccess;
        case TagStatus.DANGER:
            return themeColorBackgroundDanger;
        case TagStatus.WARNING:
            return themeColorBackgroundWarning;
    }
};

const getStatusColor = (status: TagStatus | undefined) => {
    const { global: { themeColorBackgroundSuccessLight, themeColorBackgroundDangerLight, themeColorBackgroundWarningLight } } = getGelTokens();

    switch (status) {
        case TagStatus.SUCCESS:
            return themeColorBackgroundSuccessLight;
        case TagStatus.DANGER:
            return themeColorBackgroundDangerLight;
        case TagStatus.WARNING:
            return themeColorBackgroundWarningLight;
    }
};

interface TagWrapperProps {
    status: TagStatus;
    isMobile: boolean;
}

const TagWrapper = styled.div<TagWrapperProps>`
    display: flex;
    justify-content: ${(props) => !props.isMobile ? "flex-start" : "center"};
    align-items: center;
    position: relative;
    font-family: "Pluto Sans Condensed", Arial, sans-serif;
    font-weight: 500;
    line-height: 1;
    text-decoration: none;
    margin: ${(props) => !props.isMobile ? "4px 8px 4px 0" : "0 8px 0 0"};
    border-radius: 16px;
    color: #000000;
    background-color: ${(props) => getStatusColor(props.status)};
    padding: 2px 8px;
    font-size: 12px;
    border: 1px solid ${(props) => getStatusColor(props.status)};
    gap: 8px;
    cursor: auto;
    width: fit-content;

    ${(props) =>
        props.isMobile &&
        `
        height: 24px;
        padding: 8px 8px;
    `}
`;

export default Tag;
