import React, { useContext, useEffect, useState } from 'react';
import {
    GelScreenDetectorContext,
    GelRowLayout,
    GelContainerLite,
    GelHeading2,
    GelParagraph,
    GelSpinner
} from '@tal-gel/components';

import axios from 'axios';
import { AdobePageName, API } from '../../constants/constants';
import { GET_HEALTH_AND_SUPPORT_PAGE_CONTENT_QUERY } from '../../graphql/queries/graphql-contentful-queries';
import SupportLovedOnes from './supportLovedOnes';
import SupportInRecovery from './supportInRecovery';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../../common/usercontext/user.context';
import HealthConnectorCampaign from './healthConnectorCampaign';
import { getGelTokens } from '@tal-gel/theming';
import styled from '@emotion/styled';
import { AdobeAnalytics } from '../../common/analytics/adobe-analytics';

const SupportContainer = () => {
    const navigate = useNavigate();

    const { global: { sizeBaseX15, sizeBaseX10, sizeBaseX3, sizeNone, sizeBaseX5, sizeBaseX2, themeColorBackgroundLight } } = getGelTokens();

    const { contextData } = useContext(UserContext)
    const { screen, isXsScreen } = useContext(GelScreenDetectorContext) as GelScreenDetectorContext;
    const [contentfulData, setcontenfulData] = useState<null | { mmcHealthAndRecoverySupportCollection: any }>(null)
    const [{ tpid }] = useCookies(['tpid']);

    if (contextData.memberDetails?.displayLegalDisclosure)
        navigate("/yourDeclaration");

    useEffect(() => {
        axios.post(API.CONTENTFUL_URL, {
            query: GET_HEALTH_AND_SUPPORT_PAGE_CONTENT_QUERY,
            variables: {
                fundName: tpid
            }
        })
            .then(async (response) => {
                setcontenfulData(response.data?.data);
            });

        AdobeAnalytics.PushPageLoadEvent(tpid, AdobePageName.HealthSupport);
    }, []);

    if (!contentfulData)
        return <GelSpinner medium overlay />

    const Spacer = styled.div(() => ({
        paddingTop: sizeBaseX2
    }));

    const showHealthConnector = contentfulData?.mmcHealthAndRecoverySupportCollection?.items[0].healthConnectorCampaignImage;

    const PageHeader = styled.div(() => ({
        padding: isXsScreen(screen) ? `${sizeBaseX15}px ${sizeBaseX5}px` : `${sizeBaseX15}px ${sizeBaseX10}px`,
        backgroundColor: themeColorBackgroundLight
    }));

    return (
        <GelContainerLite style={{ padding: sizeNone, overflow: "hidden" }}>
            <PageHeader >
                <GelHeading2>{contentfulData?.mmcHealthAndRecoverySupportCollection?.items[0].pageHeader}</GelHeading2>
                <GelParagraph style={{ paddingTop: sizeBaseX3 }}>{contentfulData?.mmcHealthAndRecoverySupportCollection?.items[0].pageSubtext}</GelParagraph>
            </PageHeader>

            <GelRowLayout style={{ padding: isXsScreen(screen) ? `${sizeBaseX10}px ${sizeBaseX5}px` : `${sizeBaseX5}px ${sizeBaseX10}px` }}>
                <SupportLovedOnes contentData={contentfulData} />
            </GelRowLayout>

            {showHealthConnector ? <HealthConnectorCampaign contentData={contentfulData} /> : <Spacer />}

            <GelRowLayout style={{ padding: isXsScreen(screen) ? `${sizeBaseX10}px ${sizeBaseX5}px` : `${sizeBaseX5}px ${sizeBaseX10}px` }}>
                <SupportInRecovery contentData={contentfulData} />
            </GelRowLayout>

        </GelContainerLite>
    );
};

export default SupportContainer;