import { GelIcon } from "@tal-gel/components";
import { getGelTokens } from "@tal-gel/theming";
import * as Styled from "./styles";
import Flexbox from "../../../common/components/FlexBox";
import { useContext } from "react";
import {
  GelScreenDetectorContext,
} from '@tal-gel/components';


const { global: { themeColorTextDefault, sizeBaseX4, sizeBaseX2 } } = getGelTokens();

interface Props extends React.ComponentProps<"a"> {
  title?: string;
  text?: string;
  date?: string;
  onClick?: () => void;
  variant?: "default" | "unread" | "hidden";
  disabled?: boolean;
}

export const CorroItem = ({
  title = "&nbsp",
  text = "&nbsp",
  date = "&nbsp",
  onClick = () => { },
  variant = "default",
  disabled: isDisabled = false,
  ...rest
}: Props) => {
  
  const Title = variant === "unread" ? Styled.UnreadTitle : Styled.ReadTitle;

  const { screen, isXsScreen } = useContext(GelScreenDetectorContext) as any; //GelScreenDetectorContext;

  return (
    <Styled.Link
      role="button"
      onClick={onClick}
      {...(isDisabled ? { tabIndex: -1, isDisabled } : { tabIndex: 0 })}
      {...rest}
      style={
        variant === "hidden"
          ? { visibility: "hidden", pointerEvents: "none" }
          : {}
      }
    >
      {variant === "unread" ? (
        <Styled.Unread>
          <Styled.HiddenText>Unread</Styled.HiddenText>
        </Styled.Unread>
      ) : null}
      <Flexbox
        paddingRight={sizeBaseX4}
        paddingTop={sizeBaseX2}
      >
        <GelIcon name="Document" width="21" color={themeColorTextDefault} />
      </Flexbox>
      <Flexbox flexDirection="column" flexGrow={1} gap={16} >
        <div>
          <Title as="p">{title}</Title>
          <Styled.SubText as="p">{text}</Styled.SubText>
        </div>
        {isXsScreen(screen) ? <Styled.DateText as="p">{date}</Styled.DateText> : null}

      </Flexbox>
      <div style={{ display: 'flex', alignItems: 'center', marginRight: `${sizeBaseX4}px` }}>
        {!isXsScreen(screen) ? <Styled.DateText as="p">{date}</Styled.DateText> : null}
      </div>


      <Styled.IconWrapper style={{ margin: isXsScreen(screen) ? 'initial' : 'auto', paddingBottom: isXsScreen(screen) ? '0px' : '4px', marginRight: '0px' }}>
        <GelIcon
          name="Download"
          width="24"
          color={themeColorTextDefault}
        />
      </Styled.IconWrapper>
    </Styled.Link>
  );
};