import React, { useContext } from "react";
import {
    GelLabel,
    GelBoxLayout,
    GelContainerLite,
    GelIcon,
    GelScreenDetectorContext,
    GelParagraph
} from '@tal-gel/components';
import { getGelTokens } from '@tal-gel/theming';
import { CLAIM_STATUS_TYPE } from "../../constants/constants";
import Tag, { TagStatus } from "../../common/components/Tag";
import styled from "@emotion/styled"


const BenefitType = (props) => {
    const { screen, isXsScreen } = useContext(GelScreenDetectorContext) as GelScreenDetectorContext;
    const { global: { sizeBaseX10, sizeBaseX6, sizeBaseHalf, themeColorIconDefault } } = getGelTokens();
    const { description, friendlyStatus, isFromHomePage, claimNumber, benefitTypeText, showBenefitType, isExpandable } = props

    const RenderChevronButton = (): React.ReactNode | null => {
        if (!isExpandable) {
            return null;
        }

        return (
            <GelIcon name={showBenefitType ? "ChevronUp" : "ChevronDown"} color={themeColorIconDefault}
                width="20px" height="20px" padding="0px 20px" />
        )

    }

    const RenderTag = (): React.ReactNode => {
        let status = "";

        switch (true) {
            case [CLAIM_STATUS_TYPE.APPROVED, CLAIM_STATUS_TYPE.PAID, CLAIM_STATUS_TYPE.FSD].includes(description):
                status = TagStatus.SUCCESS;
                break;
            case description === CLAIM_STATUS_TYPE.BR || description.includes(CLAIM_STATUS_TYPE.RTS):
                status = TagStatus.WARNING;
                break;
            case [CLAIM_STATUS_TYPE.CS, CLAIM_STATUS_TYPE.CN, CLAIM_STATUS_TYPE.ST, CLAIM_STATUS_TYPE.RFI].includes(description):
                status = TagStatus.DANGER;
                break;
            default:
                break;
        }

        return <Tag text={friendlyStatus} status={status} />;
    }

    return (
        <GelContainerLite style={{
            padding: isFromHomePage ? "25px 16px" :
                `${sizeBaseX6}px ${sizeBaseX10}px`
        }}>
            <GelBoxLayout space={!isXsScreen(screen) ? [7, 0.1, 0.04] : [1, 0.01, 0.05]} gutter={sizeBaseHalf}
                style={{
                    marginRight: isFromHomePage ? '-14px' : '0px',
                    marginLeft: '0px',
                    marginBottom: '4px',
                    flexDirection: !isXsScreen(screen) ? "row" : "column",
                    alignItems: !isXsScreen(screen) ? "center" : "normal"
                }}
                childStyle={{
                    paddingLeft: '0px',
                    paddingRight: '0px',
                }}>

                <GelLabel style={{
                    display: "block",
                }}>{benefitTypeText}
                </GelLabel>

                {isXsScreen(screen) ? (<GelParagraph style={{ fontSize: 14, fontWeight: 400, margin: "4px 0" }}>
                    Claim number: {claimNumber}
                </GelParagraph>)
                    : null}

                <RenderTag />

                {!isXsScreen(screen) ? <RenderChevronButton /> : null}

            </GelBoxLayout>

            {isXsScreen(screen) ? <ChevronElementWrapper>
                <RenderChevronButton />
            </ChevronElementWrapper> : null}

            {!isXsScreen(screen) ?
                <GelParagraph style={{ fontSize: 14, fontWeight: 400 }}>Claim number: {claimNumber}</GelParagraph> : null}

        </GelContainerLite>
    );
}

const ChevronElementWrapper = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0
`

export default BenefitType;