import React from 'react';
import { Routes, Route } from 'react-router-dom';
import LoginPage from './account/loginPage';
import ServerError from './common/ServerError';
import { ProtectedRoute } from '../common/authentication/secure.route';
import TwoFactorAuth from './account/twoFactor';
import ClaimsContainer from './dashboard/claims.container';
import SignUpPage from './account/signUpPage';
import NeedHelpPage from './common/needHelp';
import HomePage from './account/homePage';
import CreatePassword from './account/createPassword';
import Activate from './account/activate';
import ConfirmMobile from './account/confirmMobile';
import ConfirmMember from './account/confirmMember';
import ClaimDetailsContainer from './claimdetails/claimdetails.container';
import SupportContainer from './support/support.container';
import YourDetailsContainer from './your-details/your-details.container';
import NoClaims from './common/noClaims';
import ForgotPassword from './account/forgotPassword';
import ResetPassword from './account/resetPassword';
import YourDeclaration from './dashboard/yourDeclaration';
import { NonProtectedRoute } from '../common/authentication/non-secure.route';
import MessageHubContainer from './messageHub/messageHub';
import PageNotFound from './common/pagenotfound';

const AppRoutes = () => {

  return (
    <Routes>
      <Route path='/' element={<HomePage />} />
      <Route path='/help' element={<NeedHelpPage />} />
      <Route path='/error' element={<ServerError />} />
      <Route path='/noClaims' element={<NoClaims />} />
      <Route path='/login' element={<LoginPage />} />

      <Route element={<NonProtectedRoute />}>
        <Route path='/login/:fund' element={<LoginPage />} />
        <Route path='/verifycode' element={<TwoFactorAuth />} />
        <Route path='/signup' element={<SignUpPage />} />
        <Route path='/forgotPassword' element={<ForgotPassword />} />
        <Route path='/account/resetPassword/:recoverytoken' element={<ResetPassword />} />
        <Route path='/account/activate/:recoverytoken' element={<Activate />} />
        <Route path='/confirmMobile' element={<ConfirmMobile />} />
        <Route path='/createPassword' element={<CreatePassword />} />
        <Route path='/confirmMember' element={<ConfirmMember />} />
      </Route>

      <Route element={<ProtectedRoute />}>
        <Route path="/claims" element={<ClaimsContainer />} />
        <Route path="/claimdetails" element={<ClaimDetailsContainer />} />
        <Route path="/support" element={<SupportContainer />} />
        <Route path="/yourdetails" element={<YourDetailsContainer />} />
        <Route path="/yourDeclaration" element={<YourDeclaration />} />
        <Route path="/messageHub" element={<MessageHubContainer />} />
      </Route>

      <Route path="*" element={<PageNotFound />} />

    </Routes>

  );
};

export default AppRoutes;
